




































import { Vue, Component, Prop, PropSync, Watch } from "vue-property-decorator";

@Component({
  name: "GenerateFormItem"
})
export default class extends Vue {
  @Prop({ type: Array, required: true })
  items!: unknown[];

  @PropSync("model", { type: Object, required: true })
  currModel!: Record<string, string>;

  currSelector: unknown = {};

  @Watch("items", { deep: true })
  resetCurrSelector() {
    this.currSelector = {};
  }

  selectChangeHandler(val: any, key: string) {
    this.currModel[key] = val.reported_agency_kind_id || val.value;
  }
}
