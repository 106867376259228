
























import { VoFileUploaded } from "@/api";
import { ElUploadInternalFileDetail } from "node_modules/element-ui/types/upload";
import { Component, ModelSync, Vue } from "vue-property-decorator";

@Component({
  name: "uploader"
})
export default class extends Vue {
  @ModelSync("value", "change")
  list!: unknown[];

  get accept() {
    return `.rar,.zip,.doc,.docx,.pdf,.jpg,.mp4,.mkv`;
  }

  get action() {
    return process.env.VUE_APP_API_BASE + "/front/file/upload";
  }

  actionHandler(
    list: ElUploadInternalFileDetail & { response: VoFileUploaded }[]
  ) {
    this.list = list.map(i => i.response);
  }
}
