































import { Vue, Component } from "vue-property-decorator";

@Component({
  name: "report-menu"
})
export default class extends Vue {
  get harmId() {
    return this.$route.params.harmId;
  }
}
