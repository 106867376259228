





































































































































import {
  DtoReportingForm,
  FormRules,
  reqAgencyKinds,
  reqArea,
  reqReportingSaveHandler,
  reqValidateCodeReporting,
  VoAgencyTypes,
  VoReportHarmTypes
} from "@/api";
import ReportMenu from "../components/ReportMenu.vue";
import Uploader from "../components/Uploader.vue";
import { Form } from "element-ui";
import { Component, ModelSync, Prop, Vue } from "vue-property-decorator";
import { State } from "vuex-class";
import GenerateFormItem from "./GenerateFormItem.vue";

@Component({
  name: "reporting",
  components: {
    Uploader,
    ReportMenu,
    GenerateFormItem
  }
})
export default class Reporting extends Vue {
  @State(state => state.harmTypes)
  harmTypes!: VoReportHarmTypes[];

  @Prop({ type: String })
  harmId?: string;

  // todo [id:123]2024年个人信息保护专项治理行动举报专区 区别于其他通用收集
  uniqueCollect = +(this.harmId as string) === 123;
  // 被举报所在区域选择列表
  countyObjList = [];

  @Prop({ type: Function })
  beforeSubmit: any;

  agencyType: VoAgencyTypes = {};
  agencyTypes: VoAgencyTypes[] = [];

  validCode = "";

  validRequesting = false;
  saveRequesting = false;

  @ModelSync("value", "change", { required: true })
  form!: DtoReportingForm;

  get formRules(): FormRules<DtoReportingForm & Record<string, string>> {
    return {
      harmTypeId: [
        {
          required: true,
          message: `请选择危害小类`,
          trigger: "blur"
        }
      ],
      agencyType: [
        {
          required: true,
          validator: (rule, val, cb) => {
            if (!this.agencyType.label) {
              cb(new Error("请选择被举报平台"));
            } else {
              cb();
            }
          }
        }
      ],
      countyObj: [
        {
          required: true,
          message: `请选择被举报对象所在区域`,
          trigger: "blur"
        }
      ],
      reportObj: [
        {
          required: true,
          message: `请输入被举报对象`,
          trigger: "blur"
        }
      ],
      content: [
        {
          required: true,
          message: `请输入举报内容`,
          trigger: "blur"
        }
      ],
      validateCode: [
        {
          required: true,
          message: `请输入验证码`,
          trigger: "blur"
        }
      ],
      siteName: [
        {
          required: true,
          message: "请输入网站名称",
          trigger: "blur"
        }
      ],
      siteUrlApp: [
        {
          required: true,
          message: `请输入内容`,
          trigger: "blur"
        }
      ],
      reportedAgencyKindId: [
        {
          required: true,
          message: `请选择内容`,
          trigger: "blur"
        }
      ],
      accountType: [
        {
          required: true,
          message: `请选择内容`,
          trigger: "blur"
        }
      ],
      platformName: [
        {
          required: true,
          message: `请输入内容`,
          trigger: "blur"
        }
      ],
      officialWebsite: [
        {
          required: true,
          message: `请输入内容`,
          trigger: "blur"
        }
      ]
    };
  }

  get currTypes() {
    return this.harmTypes.length
      ? this.harmTypes.find(i => i.id === this.harmId)?.children
      : [];
  }

  get validImage() {
    return `data:image/png;base64,${this.validCode} `;
  }

  agencyTypeLoading = false;
  async initAgency() {
    try {
      this.agencyTypeLoading = true;
      const { data } = await reqAgencyKinds();
      this.agencyTypes = data;
      if (this.$route.params.harmId === "109") this.agencyType = data[1];
    } catch (error) {
      //
    } finally {
      this.agencyTypeLoading = false;
    }
  }

  async initValid() {
    try {
      this.validRequesting = true;
      const { data } = await reqValidateCodeReporting(
        this.form.indexNumber || ""
      );
      this.validCode = data;
    } finally {
      this.validRequesting = false;
    }
  }

  async saveHandler() {
    try {
      if (this.beforeSubmit) await this.beforeSubmit();
      await (this.$refs.form as Form).validate();
    } catch (error) {
      return this.$message.error(`请检查输入信息是否正确`);
    }
    try {
      this.saveRequesting = true;
      const { data: msg } = await reqReportingSaveHandler(this.form);
      sessionStorage.setItem("reporting-msg", msg);
      this.$router.push("/report/result");
    } catch (err) {
      this.$message.error(err.response.data.message);
      this.initValid();
    } finally {
      this.saveRequesting = false;
    }
  }

  resetHandler() {
    this.agencyType = {};
    (this.$refs.form as Form).resetFields();
    if (this.$route.params.harmId === "109")
      this.agencyType = this.agencyTypes[1];
  }

  agencyTypeChangeHandler(val: any) {
    if (val.label === "网站网页") {
      this.form.reportedAgencyKindId = val.reported_agency_kind_id;
    } else {
      this.form.reportedAgencyKindId = "";
    }
  }

  async getCountyObjList() {
    const res = await reqArea("500100");
    this.countyObjList = res.data?.map(item => ({
      label: item.name,
      value: item.code
    })) as never;
  }

  created() {
    this.initAgency();
    this.getCountyObjList();
  }
}
